<template>
  <div class="login-page">
		<div class="inner-page">

        <div class="modal login" :class="{show: !loading}">
          <h3>Client Login</h3>
          <form @submit="submitForm($event)" method="post" action="">
            <input placeholder="Email" name="email" type="text"/>
            <input placeholder="Password" name="password" type="password"/>
            <input class="btn" type="submit" value="Login">
            <span>Forgot your login? <a href="/reset-password">Reset Password</a></span>
          </form>
        </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: 'Login',
    data() {
      return {
        loading: true,
      };
    },
    mounted(){
      this.$nextTick(function () {
        setTimeout(function(){
          this.loading = false;
        }.bind(this),200);

        this.$store.commit('setLoading', false);
      });
    },
    methods: {
      submitForm(event){
        event.preventDefault();
      }
    }
  }
</script>

<style lang="scss">

	@import "../scss/abstracts/_variables.scss";

  .login-page .inner-page {

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;

    .modal.login {

      width: 450px;
      background: $gray-800;
      box-shadow: 0px 23px 80px -10px rgba(0, 0, 0, 0.6);
      padding: $margin-l*0.8;
      opacity:0;
      transform: scale3d(.75,.75,.75);
      transition-property: transform opacity;
      transition-duration: 500ms;
      transition-timing-function: cubic-bezier(.08,.82,.17,1);

      &.show {
        opacity: 1;
        transform: scale3d(1,1,1);
      }

      input[type="text"],input[type="password"]{
        background:$gray-900;
        margin-bottom:10px;
      }

      span {
        
        text-align: center;
        display:block;
        padding: 16px 0px 5px;;
        color: $gray-300;
        
        a {
          color: $text-color;
          padding-left:5px;
          
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }

      }

      
    }

  }

</style>